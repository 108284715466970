<template>
  <div class="about p-5 overflow-hidden container-fluid" id="dementie-information">
      <p v-if="errorMessage" v-bind:class="{'error': errorMessage}" v-bind:style="{ color: 'red' }">{{ errorMessage }}</p>
    <!-- Only display the data if the errorMessage property is not set -->
    <div v-if="!errorMessage">
    <h1 class="title">Quickscan</h1>

    <div class="container" v-if="index===1">
      <div class="row">
        <div class="col paragraphs">
          <p>Beste deelnemer, </p>
            <p>Voor je ligt de quickscan van de Zorgstandaard Dementie (ZSD). De quickscan ZSD is bedoeld om je inzicht te geven in de mate waarin je als netwerk dementie werkt volgens de Zorgstandaard Dementie. Als je deze jaarlijks invult, kun je ook de voortgang monitoren. De quickscan ZSD is ontwikkeld door Vilans en Amsterdam Data Collective samen met het veld. Opdrachtgever is Dementie Netwerk Nederland (DNN), financier is het ministerie van VWS.</p>
            <p>Iemand met een totaalbeeld van het netwerk en zicht op de realisatie van de aanbevelingen uit de Zorgstandaard Dementie vult deze quickscan in. Dit kan bijvoorbeeld de netwerkcoördinator zijn. Kun je een vraag niet beantwoorden, neem dan contact op met mensen in je netwerk die hier meer zicht op hebben. Als je netwerk uit meerdere subnetwerken bestaat, vul dan het beeld van het netwerk als geheel in.</p>
            <p>We stellen je eerst enkele vragen over jezelf/het netwerk waar je voor werkt. Daarna volgen vragen over de mate waarin de aanbevelingen uit de zorgstandaard zijn gerealiseerd. Door bij iedere pagina op “Volgende” te klikken worden de antwoorden tussentijds opgeslagen.</p>
            <p>Om deel te kunnen nemen aan de maatwerktrajecten die DNN biedt, is het invullen van de quickscan verplicht. De resultaten komen openbaar beschikbaar op deze website. Druk op “Verder” als je hiermee akkoord bent.</p>
            <p>Voor vragen kun je terecht bij het secretariaat van DNN (<a tabindex="0" class="link" href="mailto:secretariaat@dementienetwerknederland.nl" autofocus>secretariaat@dementienetwerknederland.nl</a>).</p>

            <p>Druk<a tabindex="0" class="link" href="https://www.zorgstandaarddementie.nl/wp-content/uploads/2023/06/Quickscan-ZSD.pdf" target="_blank"> hier </a>om de vragen te downloaden.</p>


<!--             <div class="d-flex flex-row">
              <div class="p-2"><p class="print-style">Print Quickscan Vragenlijst</p></div>
              <div class="p-2">
                <template>
                  <div>
                    <a href="/files/20230321_TextQuickscanZorgstandaardDementie.pdf" download>
                      <font-awesome-icon class="custom-spacing-top print-button" icon="download" />
                    </a>
                  </div>
                </template>
              </div>
            </div> -->
        </div>
      </div>
    </div>

    <div v-if="questions" class="form form-space">
      <div v-if="index===1">
        <div class="mb-3 row"></div>
        <div class="select-functie d-flex justify-content-between align-items-center">
          <label 
          class='col-sm-6 label-text' 
          for="selectPositie">Ben je netwerkcoördinator of heb je een andere functie?</label>
          <b-form-select 
          aria-label="Selecteer uw positie" autocomplete="on"
          id="selectPositie" 
          v-model="netwerkcoordinator.functie" 
          :options="functie" 
          size="lg" 
          class="col-sm-6 custom-select-form" 
          required></b-form-select>
      </div>
      <div class="mb-3 row"></div>
      <div v-if="netwerkcoordinator.functie==='Ik heb een andere functie'" class="select-functie-anders d-flex justify-content-between align-items-center">
        <label class='col-sm-6 label-text' for="andere_functie"></label>
        <input class= "custom-input" placeholder="Namelijk" v-model="netwerkcoordinator.andere_functie"/>
      </div>
      <div class="mb-3 row"></div>
        <div class="select-uren d-flex justify-content-between align-items-center">
          <label class='col-sm-6 label-text' 
          for="SelectNetwerkcoördinatieUren">Hoeveel uur per week heeft het netwerk dementie formeel voor netwerkcoördinatie?</label>
          <b-form-select 
          aria-label="Selecteer wekelijkse netwerkcoördinatie-uren"
          id="SelectNetwerkcoördinatieUren" 
          v-model="netwerkcoordinator.uren" autocomplete="on"
          :options="uren" 
          size="lg" 
          class="col-sm-6 custom-select-form" required></b-form-select>
      </div>
      <div class="mb-3 row"></div>

        <button @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabled1">Volgende</button>
      </div>

      <div v-if="index===2 && this.vragengroep_id == 1">
        <div>
          <p style="margin-bottom: 50px">Hieronder staat een aantal partijen die mogelijk in jouw netwerk dementie vertegenwoordigd zijn. Per partij willen we graag weten: is deze vertegenwoordigd in jouw netwerk, is deze bestuurlijk gecommitteerd, en draagt deze financieel dan wel in natura bij aan het netwerk. </p>
        </div>
        <div>
          <div class="align-items-center justify-content-between table-header">
            <div class="p-2" style="width: 290px;"></div>
              <div class="row">
                  <div class="col-sm-3">
                    <p></p>
                  </div>
                  <div class="col-sm-3">
                    <p>{{ filteredQuestions2(0)[0].vraag.split('-')[1] }}</p>
                  </div>
                  <div class="col-sm-3">
                    <p>{{ filteredQuestions2(0)[1].vraag.split('-')[1] }}</p>
                  </div>
                  <div class="col-sm-3">
                    <p>{{ filteredQuestions2(0)[2].vraag.split('-')[1] }}</p>
                  </div>
              </div>
          </div>

        <template v-for="index in index_array">
          <div class="align-items-center justify-content-center" :key="`ques-${index}`">
            <div class="row">
              <div class="col-sm-3">
                <div class="p-2">
                  <div class="question-group" v-if="shouldDisplayHeader(index)" 
                  style="width: 290px; margin-right: 5px;">
                    {{ filteredQuestions2(index)[0].vragengroep }}
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in options2">
                      <div class="d-inline-flex justify-content-around options-questions" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" class="button-label nee-ja-button" :class="{ 'radio-selected': filteredQuestions2(index)[0].antwoord == item.value }" 
                        @keyup.enter="selectRadioButton(index, 0, item.value)">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="filteredQuestions2(index)[0].antwoord" :value="item.value" />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>

              <div class="col-sm-3">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in options2">
                      <div class="d-inline-flex justify-content-around options-questions" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" class="button-label nee-ja-button" :class="{ 'radio-selected': filteredQuestions2(index)[1].antwoord == item.value }" 
                        @keyup.enter="selectRadioButton(index, 1, item.value)">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="filteredQuestions2(index)[1].antwoord" :value="item.value" />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>

              <div class="col-sm-3">
                <div class="options-container options-radio-button">
                    <template v-for="(item, idx) in options2">
                      <div class="d-inline-flex justify-content-around options-questions" :key="`radio-${idx}`"  style="margin-left: 20px">
                        <label tabindex="0" class="button-label nee-ja-button" :class="{ 'radio-selected': filteredQuestions2(index)[2].antwoord == item.value }" 
                        @keyup.enter="selectRadioButton(index, 2, item.value)">
                          {{ item.text }}
                          <input class="hidden radio-label" type="radio" :name="`question-${index}`" 
                          v-model="filteredQuestions2(index)[2].antwoord" :value="item.value" 
                          />
                        </label>
                      </div>
                    </template>
                  </div>
                </div>
            </div>
          </div>
        </template>

        <div class="mb-3 row"></div>
          <div>
            <label 
            class='col-sm-4 label-text' 
            for="missingParties">Welke partijen mist u in het bovenstaande overzicht?</label>
            <input 
            aria-label="Voer ontbrekende partijen in" 
            id="missingParties"
            class= "col-sm-7 custom-input" 
            v-model="netwerkcoordinator.partijen"/>
        </div>

        </div>
            
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-start flex-grow-1 bd-highlight">
            <button v-if="this.vragengroep_id == 1" type="button" @click="previousPage" class="btn btn-primary lager">Vorige pagina</button>
            <button v-if="this.vragengroep_id > 1" type="button" @click="PrevQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledPrev">Vorige pagina</button>
          </div>
          <div class="d-flex justify-content-end">
              <button type="button" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledNext">Volgende pagina</button>
              <button v-if="this.vragengroep_id == 11" type="submit" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabled2">Verstuur</button>
          </div>
        </div>
      </div>

       <div v-if="index===3 && this.vragengroep_id >= 14">
        <div class="mb-3 row"></div>
        <div class="information-text">
        <p class="p-text">Realisatie aanbevelingen Zorgstandaard Dementie <br> <br>
          Hieronder leggen we je per thema een aantal stellingen voor. Deze stellingen zijn gebaseerd op de aanbevelingen uit de Zorgstandaard Dementie. Geef per stelling aan in hoeverre dit binnen jouw netwerk is gerealiseerd.  
        <br><span class="p-italic">(1 = helemaal niet gerealiseerd, 2 = grotendeels niet gerealiseerd, 3 = ongeveer de helft gerealiseerd, 4 = grotendeels gerealiseerd, 5 = volledig gerealiseerd).</span>
        </p>
        </div>

        <div>
          <div class="mb-3 row">
            <div v-for="(question, index) in filteredQuestions" :key="question.id">
              <h2 v-if="shouldDisplayHeader(index)" class="question-group">{{ question.vragengroep }}</h2>
              <fieldset>
                <legend class="sr-only">{{ question.vraag }}</legend>
                <p v-if="shouldDisplayHeader(index) && question.vragengroep_id==10">NB. Mogelijk zijn er meerdere verpleeghuizen in uw netwerk, die onderling van elkaar verschillen. Als dat zo is, dan willen we u vragen een antwoord in te vullen dat het beste de realisatie van alle verpleeghuizen gemiddeld weergeeft.</p>
                <div class="mb-3 row">
                  <p class="question">{{ question.vraag }}</p>
                    <div class="rb">
                      <label 
                      class="rb-tab" 
                      :class="{ 'rb-tab-active': question.antwoord == item.value }" 
                      v-for="(item, idx) in options" 
                      :key="`1a-inner-loop-tab-${idx}`">
                        <input 
                        class="rb-input" 
                        v-model="question.antwoord" 
                        :value="item.value" 
                        type="radio" 
                        :name="`question-${index}`" 
                        :id="`flexRadioDefault1-${idx}`"
                        :checked="question.antwoord == item.value" 
                        required>
                        <div 
                        class="rb-spot" 
                        tabindex="0" 
                        @keyup.enter="selectSecondRadioButtons(question, item.value)"
                        :class="{ 'rb-spot-active': question.antwoord == item.value }">
                          <span class="rb-txt">{{ item.text }}</span>
                          <span class="rb-txt-nvt">{{ item.text2 }}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </fieldset>
                </div>
              </div>
            </div>
            
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-start flex-grow-1 bd-highlight">
                <button v-if="this.vragengroep_id == 14" type="button" @click="previousPage" class="btn btn-primary lager">Vorige pagina</button>
                <button v-if="this.vragengroep_id > 14" type="button" @click="PrevQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledPrev">Vorige pagina</button>
              </div>
              <div class="d-flex justify-content-end">
                <button v-if="this.vragengroep_id != 23" type="button" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabledNext">Volgende pagina</button>
                <button v-if="this.vragengroep_id == 23" type="submit" @click="nextQuestionGroup" class="btn btn-primary lager" :disabled="isDisabled2">Verstuur</button>
              </div>
            </div>
          </div> 
        </div>


        <div v-if="index===4">
          <div v-if="saved" class="alert alert-success custom-alert" role="alert">
            Antwoorden opgeslagen, bedankt!
          </div>
          <button class="btn btn-secondary" @click="backToMenu" autofocus>Terug naar menu</button>
        </div>
      </div>
      </div>
    </template>

<script>
  import { mapGetters } from 'vuex'
  import VueMeta from 'vue-meta'

  export default {
    mixins: [VueMeta],
    metaInfo: {
      title: 'Quickscan',
    },
    data() {
      return {
        url: "",
        errorMessage: '',
        user_antwoord_id:0,
        vragengroep:'',
        vraag:'',
        vraag_id : 1,
        vragengroep_id:1,
        id: '',
        activeGroupIndex: 0,
        netwerkcoordinator: {
          dementienetwerk: '',
          user_id: '',
          functie: '',
          andere_functie: '',
          partijen: '',
          uren: '',
          antwoorden: [],
        },
        options: [
          { text: '1', text2: 'Helemaal niet gerealiseerd', value: 1 },
          { text: '2', text2: 'Grotendeels niet gerealiseerd', value: 2 },
          { text: '3', text2: 'Ongeveer de helft gerealiseerd', value: 3 },
          { text: '4', text2: 'Grotendeels gerealiseerd', value: 4 },
          { text: '5', text2: 'Volledig gerealiseerd', value: 5 }
        ],
        options2: [
          { text: 'Nee', value: 1 },
          { text: 'Ja', value: 2 },
        ],
        index_array: [0,3,6,9,12,15,18,21,24,27,30,33],
        index: 1,
        functie: ['Ik ben netwerkcoördinator', 'Ik heb een andere functie'],
        uren: ['0-4 uur', '5-8 uur', '9-12 uur', '13-16 uur', '17-24 uur', '25-32 uur', '>32 uur'],
        questions: null,
        saved: false,
        error: false,
      }
    },
    computed: {
      ...mapGetters({userInfo: 'userInfo' }),

    filteredQuestions() {
        const uniqueGroups = [...new Set(this.questions.map(question => question.vragengroep))];
        const filteredQuestions = this.questions.filter((question) => {
          this.vragengroep = question.vragengroep;
          if (this.vragengroep_id === 1) {
            return question.vragengroep_id === 1;
          } 
          else {
            return question.vragengroep === uniqueGroups[this.vragengroep_id - 2];
          }
        });
        return filteredQuestions;
      },


      isDisabled1() {
        return (this.netwerkcoordinator.functie == '' || this.netwerkcoordinator.uren == '' ||
        (this.netwerkcoordinator.functie == 'Ik heb een andere functie' && this.netwerkcoordinator.andere_functie == '')) == true
      },
      isDisabled2() {
        return (!this.vragengroep_id == 9 || this.filteredQuestions.some(obj => obj.antwoord == '') || this.netwerkcoordinator.functie == '' || this.netwerkcoordinator.uren == '') == true
      },
      isDisabledNext(){
        if (this.vragengroep_id == 23 || this.filteredQuestions.some(obj => obj.antwoord == '')) {
          return true;
        }
        else{
          return false;
         }
      },
      isDisabledPrev(){
        return (this.vragengroep_id == 0) == true
      }
    },
    mounted () {
      this.$http.get('/api/vragen_quickscan/').then((response) => {
        response.data.sort((a,b) => a.id-b.id)
        this.questions = response.data.map((question,index) => {
        const koppeltabelquickscan = question.koppeltabelquickscan[0] // Assuming there is only one object in the array
        return {
          id: question.id,
          vraag: question.vraag,
          vraag_id : koppeltabelquickscan.vraag_id,
          vragengroep_id: koppeltabelquickscan.vragengroep_id,
          vragengroep: koppeltabelquickscan.vragengroep,
          antwoord : this.user_antwoord_id[index] || ''
        }
      })
      }).catch(error => {
          console.error(error)
          if(error.response.status == 500){
            this.errorMessage = 'Er zijn nog geen quickscan vragen bekend. Zodra deze worden aangeleverd, zullen ze hier worden toegevoegd.' 
          }
          else if(error.response.status == 401){
            this.errorMessage = 'U hebt geen toegang tot deze pagina.' 
          }
          else {
            this.errorMessage = 'Onbekende foutmelding. Contacteer alstublieft dementiedata@amsterdamdatacollective.com.'
          }
    })
      // load previous data filled by user if any
      this.loadData();
    },
    methods: {
      selectRadioButton(questionIndex, optionIndex, selectedValue) {
        // the question object based on the questionIndex
        const question = this.filteredQuestions2(questionIndex)[optionIndex];

        // the selected option for the question
        question.antwoord = selectedValue;
      },
      selectSecondRadioButtons(question, selectedValue) {
        question.antwoord = selectedValue;
      },
      filteredQuestions2(index) {
        return this.filteredQuestions.slice(index,index+3)
      },
      previousPage() {
        if (this.index == 3) {
          this.vragengroep_id = 1;
        }
        this.index = this.index - 1
      },
      shouldDisplayHeader(index) {
      // checking if the current question has the same vragengroep value as the previous question
      if (index === 0 || this.filteredQuestions[index].vragengroep !== this.filteredQuestions[index - 1].vragengroep) {
        return true;
      } else {
        return false;
      }
    },
    shouldDisplayQuestion(index) {
      // checking if the current question is the three questions of the first vragengroep
      if (index === 0 || index === 1 || index === 2) {
        return true;
      }

      return false;
    },
      nextQuestionGroup(){
        // assigning dementienetwerk and user id to the data
        //this.netwerkcoordinator.dementienetwerk=localStorage.getItem('Dementienetwerk')
        this.netwerkcoordinator.dementienetwerk = this.userInfo.dementienetwerk
        this.netwerkcoordinator.user_id = this.userInfo.id
        this.netwerkcoordinator.antwoorden=this.filteredQuestions
        if (this.netwerkcoordinator.functie === 'Ik ben netwerkcoördinator') {
          this.netwerkcoordinator.andere_functie = ''
        }

        if (this.index === 3) {
          const requestBody = {
          netwerkcoordinator : this.netwerkcoordinator,
          submit_at_next : true // parameter to send backend for submitting on the next button
        }
          this.$http.post('/api/netwerkcoordinators/', requestBody)

          if (this.vragengroep_id == 23) {
            this.questions = null
            this.index = this.index + 1
          }
          else{
            this.vragengroep_id = this.vragengroep_id + 1
          }
        }

        if (this.index == 4) {
          this.saved = true
        }

        // sending the API to save answers when clicked on button Next in each page
        if (this.index === 2) {
          const requestBody = {
          netwerkcoordinator : this.netwerkcoordinator,
          submit_at_next : true // parameter to send backend for submitting on the next button
        }
          this.$http.post('/api/netwerkcoordinators/', requestBody)

          if (this.vragengroep_id == 1) {
            //this.questions = null
            this.index = this.index + 1
            this.vragengroep_id = 14
          }
          else{
            this.vragengroep_id = this.vragengroep_id + 1
          }
        }

        if (this.index === 1) {
          const requestBody = {
          netwerkcoordinator : this.netwerkcoordinator,
          submit_at_next : false
        }
          this.$http.post('/api/netwerkcoordinators/', requestBody)
          this.index = this.index + 1
        }
      },

      PrevQuestionGroup(){
        if (this.vragengroep_id === 0) {
          this.vragengroep_id = 0
        }
        else{
          this.vragengroep_id = this.vragengroep_id - 1
        }
      },
      vorige() {
        if (this.index>1) {
          this.index = this.index - 1
        }
      },
      loadData(){
        this.$http.get('api/netwerkcoordinators/get_antwoord_based_on_user').then((response)=>{
          // check if the response is array or not
          if (Array.isArray(response.data)) {
            response.data.sort((a,b) => a.id - b.id)
            this.user_antwoord_id = response.data.map(d => d.antwoord_id) // answer id
            this.user_vraag_id = response.data.map(d => d.vraag_id) // question id
            this.user_uren = response.data.map(d => d.uren)
            this.user_functie = response.data.map(d => d.functie)

            if (response.data[0]) {
              this.netwerkcoordinator.functie = response.data[0].functie;
              this.netwerkcoordinator.uren = response.data[0].uren;
            } else {
              this.netwerkcoordinator.functie = '';
              this.netwerkcoordinator.uren = '';
            }
          }
        })
      },
      backToMenu() {
        this.$router.push('/zorgstandaard/Netwerkcoordinator/')
      },
    }
  }
</script>

<style scoped>
.col-sm-1 {
text-align: center;
}
.col-sm-6 {
text-align: left;
}
.mb-3{
text-align: left;
}
.col-sm-3 {
  text-align: center;
}
.col-md-3 {
  margin-left: 5px;
}
.col-sm-2 {
  text-align: center;
}
.lager {
  margin-top: 30px;
  font-weight: bold;
}

.btn {
  float: right;
  background-color: var(--color-1);
  border-color: var(--color-2);
  margin-bottom: 20px;
  transition: all .2s;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
  background: var(--color-14);
  color: var(--color-2);
  transform: translateY(-3px);
}

.btn:hover{
  outline: none !important;
  box-shadow: none !important;
  background: var(--color-14);
  color: var(--color-2);
  transform: translateY(-3px);
}

.paragraphs{
  background-color: var(--color-2) !important;
}

.label-text{
  color: var(--color-1);
  font-weight: bold;
}

.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.form-space{
  margin-top: 2rem;
}

.custom-select-form{
  font-size: 16px;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 40px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.custom-select-form{
  font-size: 16px;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 40px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.custom-select-form:focus{
  outline: none !important;
  border: 3px solid var(--color-1);
  background-color: rgba(57, 57, 57, 0.15);
}

.custom-select-form:hover{
  background-color: rgba(57, 57, 57, 0.15);
}

.link{
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
  color: var(--color-1);
  font-weight: bold;
}

.link:visited {
  color: var(--color-7);
}

.link:hover {
  background-color: rgba(57, 57, 57, 0.15);
  border-radius: .5em;
}

.link:focus {
  outline: none !important;
  border-radius: .5em;
  border: 3px solid var(--color-1);
}

.title{
  margin-bottom: 2rem;
}

.rb-box {
  width: 80%;
  max-width: 420px;
  margin: 50px auto;
  padding: 1.3em;
  background: var(--color-1);
  border-radius: .75em;
  -webkit-filter: drop-shadow(1px 2px 5px rgba(0,0,0,.3));
  filter: drop-shadow(1px 2px 5px rgba(0,0,0,.3));
  box-shadow: 
    0 2px 2px rgba(243,49,128,.5),
    0 0px 5px rgba(243,49,128,.15),
    0 0px 4px rgba(0,0,0,.35),
    0 5px 20px rgba(243,49,128,.25),
    0 15px 50px rgba(243,49,128,.75),
    inset 0 0 15px rgba(255,255,255,.05);
}

/* Custom Radio Button */
.rb {
  padding: 16px 0;
  text-align: center;
  background: rgba(255,255,255,.03);
  border-radius: .3em;
}

.rb-tab {
  display: inline-block;
  position: relative;
  width: 20%;
  cursor: pointer;
}

.rb-tab-active .rb-spot {
  background: var(--color-1);
  textcolor: var(--color-2);
  border: 2px solid var(--color-1);
}

.rb-tab-active .rb-txt {
  color: var(--color-2);
}

.rb-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: .8em;
  font-weight: bold;
  color: var(--color-1);
}

.rb-txt-nvt {
  position: absolute;
  top: 210%;
  left: 50%;
  width: 110px;
  max-width: 110px;
  transform: translate(-50%, -50%);
  font-size: .8em;
  color: var(--color-1);
  text-align: center;
}

.rb-spot {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 1.5px solid var(--color-1);
  border-radius: 100%;
  transition: border 0.15s ease, background-color 0.15s ease;
}

.rb-spot:hover,
.rb-spot:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--color-14);
  color: var(--color-2) !important;
  border: 2px solid var(--color-14);
}

.rb-spot:hover .rb-txt,
.rb-spot:focus .rb-txt {
  color: var(--color-2);
}

.rb-input{
  visibility: hidden;
}

.p-text{
  padding: 1rem;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-2);
}

.p-italic{
  font-style: italic;
  font-size: 16px;
}


.information-text{
  padding: 1rem;
  background-color: var(--color-1);
  margin: 2rem 0 2rem 0;
  border-radius: 30px;
}


.question-group{
  font-weight: bold;
  margin: 2rem 0 2rem 0;
}

.question{
  font-weight: bold;
  margin-top: 2rem;
}


.print-style{
  font-weight: bold;
  font-style: italic;
  color: var(--color-11);
}

.print-button{
  color: var(--color-1);
  font-size: 16px;
  cursor: pointer;
}

.custom-input{
  background: rgba(57, 57, 57, 0.07);
  border: none;
  border-radius: 10px;
}

.custom-input:focus{
  background-color: rgba(57, 57, 57, 0.15);
  border: 3px solid var(--color-1);
  border-radius: 10px;
}

.custom-input:hover{
  background-color: rgba(57, 57, 57, 0.15);
  border: none;
}

input {
  margin: 10px 0;
  height: 40px;
  padding: 0 30px;
}

input{
  outline: none;
}

input::placeholder{
  font-style: italic;
  color: var(--color-10);
}

.button-wrap {
  position: relative;
  text-align: center;
  top: 10%;
  margin-top: -2.5em;
  @media (max-width: $small) {margin-top: -1.5em;}
}

.button-label {
  text-align: center;
  font-size: 16px;
  width: 60px;
  display: inline-block;
  padding: 1em 1em;
  margin: 0.5em;
  cursor: pointer;
  color: $dark;
  border-radius: 0.25em;
  background: $light;
  box-shadow: 0 3px 10px rgba(0,0,0,0.2), inset 0 -3px 0 rgba(0,0,0,0.22);
  transition: 0.3s;

  @media (max-width: $small) {
    padding: 0em 1em 3px;
    margin: 0.25em;
  }
}


.button-label:focus {
  outline: none;
  box-shadow: none;
}

.nee-ja-button{
  color: var(--color-1);
  background: var(--color-2);
  font-weight: bold;
}

.nee-ja-button:hover{
  color: var(--color-2);
  background: var(--color-1);
}

.nee-ja-button:focus{
  color: var(--color-2);
  background: var(--color-14);
}



.radio-selected {
  background-color: var(--color-1);
  color: var(--color-2);
}


.hidden {
  display: none;
}

.table-header{
  color: var(--color-2);
  background: var(--color-1);
  font-weight: bold;
}

.table-sideheader{
  color: var(--color-1);
  font-weight: bold;
}

.options-radio-button:not(:first-child) {
  margin-left: 50px;
}

.options-questions:not(:first-child) {
  margin-right: 20px;
}


@media (max-width: 1250px) {
  .d-flex {
    flex-wrap: wrap;
  }

  .options-container {
    width: 100%;
  }
}


.btn-secondary {
  background: var(--color-1);
  font-weight: bold;
  height: 50px;
  padding-top: 5px !important;
  z-index: 12;
  margin-top: 30px;
  margin-right: 50px;
  width: 200px;
  max-width: 300px
}

.btn-secondary:focus {
  outline: none !important;
  box-shadow: none !important;
  background: #e8f0fe;
  color: var(--color-1);
  border:2px rgb(20,66,115) solid !important;
}

.btn-secondary:hover {
  outline: none !important;
  box-shadow: none;
  background: var(--color-4);
}

.custom-alert{
  font-weight: bold;
}
</style>;